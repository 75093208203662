:where(.css-dev-only-do-not-override-j0nf2s).ant-pagination
  .ant-pagination-item {
  border-radius: 50% !important;
}
:where(.css-dev-only-do-not-override-j0nf2s).ant-pagination
  .ant-pagination-disabled
  .ant-pagination-item-link {
  background-color: #fbf6f7 !important;
  border-radius: 50% !important;
  border: none !important;
}

.ant-pagination .ant-pagination-next,
.ant-pagination-prev {
  background-color: #940e34 !important;
  border-radius: 50% !important;
}
.ant-pagination-next span,
.ant-pagination-prev span {
  color: #fff !important;
}
:where(.css-dev-only-do-not-override-j0nf2s).ant-pagination
  .ant-pagination-disabled {
  /* border: 1px solid red; */
}
/* .ant-select-arrow {
  display: none !important;
} */
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-inline-end: 47px !important;
}
.ant-pagination-disabled span {
  color: #940e34 !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-pagination
  .ant-pagination-item-active {
  border-color: #e8e8e8 !important;
}