.teacher-page-layout-web {
  .teacher-page-sider {
    position: fixed;
    z-index: 2;
    flex: 0 0 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    width: 300px !important;
    .ant-layout-sider-children {
      height: 100vh;
      background: rgb(148, 14, 52);
      .teacher-page-sider-img {
        height: 80px;
        background: #ffffff;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 67%;
        }
      }
      .teacher-page-sider-menu {
        background: rgb(148, 14, 52);
        padding: 20px 0px 0px 0px;
        border-radius: 0px;
        .ant-menu-item {
          background: transparent;
          font-size: 20px;
          margin: 25px 0px;
          width: 100%;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          .ant-menu-title-content {
            color: #ffffff;
          }
        }
        .ant-menu-item-selected {
          background: rgb(1, 40, 52);
        }
      }
    }
  }
  .teacher-page-sublayout {
    .teacher-page-header {
      z-index: 1;
      background: #ffffff;
      display: grid;
      align-items: center;
      padding: 0px 0px 0px 300px;
      box-shadow: 0px 0px 3px #000000;
      margin: 0px;
      height: 80px !important;
      position: fixed;
      width: 100%;
      line-height: 14px !important;
    }
    .teacher-page-content {
      padding: 83px 0px 0px 0px;
    }
    .teacher-page-footer {
      height: 67px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 0px 0px 300px;
      color: rgba(0, 0, 0, 0.88);
      font-size: 20px;
      font-weight: 700;
      background: #ffffff;
    }
  }
}

.teacher-page-layout-mobile {
  .teacher-page-header {    
    z-index: 1;
    height: 80px;
    background: #ffffff;
    position: fixed;
    top: 0;
    padding: 0px;
    width: 100%;
      line-height: 14px !important;
  }
  .teacher-page-content {
    padding: 83px 0px 0px 0px;
  }
  .teacher-page-footer {
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.88);
    font-size: 20px;
    background: #FFFFFF;    
    font-weight: 700;
  }
}

.ant-drawer {
  .ant-drawer-content-wrapper {
    width: 260px !important;
  .teacher-page-layout-drawer {
    width: 260px;
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        display: none;
      }
      .ant-drawer-body {
        padding: 0px;
        background: rgb(148, 14, 52);
        .teacher-page-sider {
          background: #ffffff;
          z-index: 2;
          max-width: 260px !important;
          min-width: 260px !important;
          width: 260px !important;
          .teacher-page-sider-img {
            height: 80px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 67%;
            }
          }
          .teacher-page-sider-menu {
            background: rgb(148, 14, 52);
            padding: 20px 0px 0px 0px;
            border-radius: 0px;
            .ant-menu-item {
              background: transparent;
              font-size: 20px;
              margin: 25px 0px;
              width: 100%;
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              .ant-menu-title-content {
                color: #ffffff;
              }
            }
            .ant-menu-item-selected {
              background: rgb(1, 40, 52);
            }
          }
        }
      }
    }
  }
}
}
