// .home-layout {
//     .home-layout-header{
//         z-index: 1;
//         background: #ffffff;
//         box-shadow: 0px 0px 3px #000000;
//         margin: 0px;
//         position: fixed;
//         width: 100%;
//     }
// }
// @media screen and (min-width: 760px) {
//     .home-layout {
//         .home-layout-header {        
//             height: 80px !important;
//         }
//         .home-layout-content {
//             padding-top: 80px;
//         }
//     }
// }

// @media screen and (max-width: 760px) {
//     .home-layout {
//         .home-layout-header {
//             height: 110px !important;
//             padding: 0px 20px;
//         }
//         .home-layout-content {
//             padding-top: 110px;
//         }
//     }
// }
// @media (max-width: 768px) {
//     .col-lg-6 {
//       max-width: 100%; /* Adjust as needed for smaller screens */
//     }
//   }

.home-layout {
  background: white;
}

.home-layout-footer {
  padding: 0;
}