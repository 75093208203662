/* layoutStyle.css */

.layout-container {
  display: flex;
  height: auto;
  max-height: 500vh !important;
}

.main-content {
  flex: 1;
  transition: margin-left 0.5s ease-in-out;
  background-color: #f8f8f8;
  overflow: hidden;
}

.sidebar-open .main-content {
  margin-left: 0;
}

.children-container {
  height: 100%;
  overflow-y: auto;
}
