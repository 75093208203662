:where(.css-dev-only-do-not-override-j0nf2s).ant-checkbox-group {
  display: grid !important;
}
:where(.css-dev-only-do-not-override-j0nf2s).ant-checkbox-wrapper
  + .ant-checkbox-wrapper {
  margin: 0 !important;
  margin-inline-start: 0 !important;
}

@media screen and (max-width: 760px) {
  .review-page {
    padding: 15px 15px 140px 15px;
  }
  .review-pagination {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

@media screen and (min-width: 760px) {
  .review-page {
    padding: 20px 20px 20px 320px;
  }
  .review-pagination {
    position: fixed;
    bottom: 0;
    padding-left: 300px;
    width: 100%;
  }
}