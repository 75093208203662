.mobile-button {
  background: #ffffff;
  border: 1px solid #940E34;
  color:#940E34;
  border-radius: 0px 20px 20px 0px;
  font-size: 14px;
  padding: 20px;
}

.admin-button {
  background: #ffffff;
  border: 1px solid #940E34;
  color: #940E34;
  border-radius: 20px 0px 0px 20px;
  font-size: 14px;
  padding: 20px;
}

.active-btn {
  background: rgba(5, 31, 50, 0.05);
  /* padding-bottom: 20px; */
  font-weight: 600;
}
.btn-post-service {
  min-width: 100%;
  min-height: 48px;
  margin-bottom: 10px;
  border-radius: 30px;
  background: white;
  color: #940e34;
  border: 2px solid #940e34;
}
.grid-post {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-begin {
  width: 100%;
  min-height: 50px;
  border-radius: 30px;
  background: #940e34;
  color: #f7f7f7;
  border: 2px solid #940e34;
}

.txt-feild {
  width: 75%;
  padding: 8px;
  margin-left: 5px;
  background: #f7f7f7
}

.location-txt-feild {
  width: 100%;
  padding: 13px;
  background:#f7f7f7
  /* margin-left: 10px; */
}
.mobile-button {
  background: #ffffff;
  border: 1px solid rgba(5, 31, 50, 0.2);
  border-radius: 0px 6px 6px 0px;
  font-size: 14px;
  padding: 20px;
}

.admin-button {
  background: #ffffff;
  border: 1px solid rgba(5, 31, 50, 0.2);
  border-radius: 6px 0px 0px 6px;
  font-size: 14px;
  padding: 20px;
}

.active-btn {
  background: rgba(5, 31, 50, 0.05);
  /* padding-bottom: 20px; */
  font-weight: 600;
}
.btn-post-service {
  padding: 10px 60px 10px 60px;
  border-radius: 20px;
  background: white;
  color: #940e34;
  border: 2px solid #940e34;
}
.grid-post {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-begin {
  width: 100%;
  border-radius: 20px;
  background: #940e34;
  color: #f7f7f7;
  border: 2px solid #940e34;
}

.txt-feild {
  width: 80%;
  padding: 8px;
  margin-left: 5px;
}

.location-txt-feild {
  width: 80%;
  padding: 13px;
  /* margin-left: 10px; */
}
.selected{
  background: #940e34;
  color: white;
}
