.accordion-button {
  border: none !important;
  background-color: #fff !important;
}

.accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}
.accordion-button:hover {
  z-index: 0 !important;
}
:where(.css-dev-only-do-not-override-j0nf2s).ant-checkbox-checked
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-j0nf2s).ant-checkbox-indeterminate
  .ant-checkbox-inner:after {
  background-color: #940e34 !important;
}
:where(.css-dev-only-do-not-override-j0nf2s).ant-checkbox .ant-checkbox-inner {
  border: 1px solid #940e34 !important;
}
.stars {
  font-size: 15px;
}
.accordion-button::after {
  border-radius: 50%;
  border: none !important;
  background-color: #faf3f5;
  color: #940e34;
  fill: #940e34;
  padding: 15px;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='crimson'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  width: 25px;
  height: 25px;
  background-position: center center;
}

.accordion {
  --bs-accordion-btn-icon-width: 1rem !important;
}

@media screen and (max-width: 768px) {
  .accordian-star {
    flex-direction: column;
  }
}
