.tab-container {
  display: flex;
  justify-content: center;
  margin-right: 7rem;
  @media only screen and (max-width: 700px) {
    display: block;
    padding: 1rem;
  }
}

.serviceTab {
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.tab {
  display: flex;
  justify-content: space-around; /* Distribute space between items */
}

.tab-item {
  list-style: none;
  margin: 10px 30px;
}
.active-tab {
  position: relative;
}
.active-tab::after {
  content: "";
  position: absolute;
  width: 100%;
  border-radius: 40px;
  height: 2px; /* Adjust the border-bottom height as needed */
  background-color: #940e34; /* Change the color as needed */
  bottom: -2px; /* Adjust the positioning to move the border below the text */
  left: 0%; /* Center the border */
  @media only screen and (max-width: 700px) {
    display: none !important;
  }
}
.in-active-tab {
  position: relative;
}
.in-active-tab::after {
  content: "";
  position: absolute;
  width: 100%;
  border-radius: 40px;
  height: 2px; /* Adjust the border-bottom height as needed */
  background-color: #000000; /* Change the color as needed */
  bottom: -2px; /* Adjust the positioning to move the border below the text */
  left: 25%; /* Center the border */
  @media only screen and (max-width: 700px) {
    display: none !important;
  }
}
.dynamic-tab-content {
  background-color: #940e34; /* Your desired background color */
  margin: 0;
  padding: 0;
  max-height: auto;
  /* height: 90vh; */
  background-size: cover;
  background-image: url("../../assets/images/banner-pattern.png"); /* Path to your pattern image */
  background-repeat: no-repeat;  /* Repeat the pattern */
}

