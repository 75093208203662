.teacher-dashboard {
  .teacher-dashboard-overview {
    font-weight: 700;
    font-size: 20px;
    color: #aa415f;
  }
  .teacher-dashboard-chart {
    margin-top: 20px;
    .teacher-dashboard-chart-container-valid {
      .teacher-dashboard-chart-data-notvalid {
      }
      .teacher-dashboard-chart-valid-data {
      }
    }
    .teacher-dashboard-chart-container-notvalid {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 350px;
      img {
        height: 100%;
      }
    }
  }
  .teacher-dashboard-scheduler-time {
    margin-top: 15px;
    .teacher-dashboard-scheduler-time-container {
      .teacher-dashboard-scheduler-time-container-scheduler {
        .teacher-dashboard-scheduler-time-container-scheduler-text {
            font-weight: 700;
            font-size: 20px;
          color: #aa415f;
        }
        .teacher-dashboard-scheduler-time-container-card {
          width: 100%;
          border: none;
          background-color: #fff;
          border-radius: 7px;
          padding: 15px 15px;
          margin-top: 1rem;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          column-count: 2;
          column-fill: auto;
          height: 315px;
        }
        .teacher-dashboard-scheduler-time-container-card-loader {
        }
        .teacher-dashboard-scheduler-time-container-card-container {
          .teacher-dashboard-scheduler-time-container-card-container-img {
            img {
            }
          }
          .teacher-dashboard-scheduler-time-container-card-container-timing {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fbf6f7;
            margin: 0.5rem;
            padding: 10px;
          }
        }
      }
      .teacher-dashboard-time-table {
        .teacher-dashboard-time-table-calender {
            font-weight: 700;
            font-size: 20px;
          color: #aa415f;
        }
        .teacher-dashboard-time-table-card {
            display: flex;
            align-items: center;
          border: none;
          background-color: #fff;
          border-radius: 7px;
          padding: 0px 15px;
          margin-top: 1rem;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          height: 315px;
          .react-calendar {
            .react-calendar__navigation {
                margin-bottom: 5px;
              .react-calendar__navigation__prev-button {
                font-size: 20px;
                border: none;
                background: transparent;
              }
              .react-calendar__navigation__next-button {
                font-size: 20px;
                border: none;
                background: transparent;
              }
              .react-calendar__navigation__label {
                background: transparent;
                border: none;
                span {
                  font-size: 15px;
                }
              }
            }
            .react-calendar__viewContainer {
                .react-calendar__month-view {
                    .react-calendar__month-view__weekdays {
                        margin-bottom: 10px;
                        .react-calendar__month-view__weekdays__weekday {
                            text-align: center;
                            font-weight: 600;
                            font-size: 9px;
                        }
                    }
                }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .teacher-dashboard {
    padding: 15px;
  }
}

@media screen and (min-width: 760px) {
  .teacher-dashboard {
    padding: 20px 20px 20px 320px;
  }
}
