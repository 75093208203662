.teacher-page-web {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .teacher-page-header-content-left {
  }
  .teacher-page-header-content-right {
    display: flex;
    align-items: center;
    .teacher-page-header-content-right-bell {
      cursor: pointer;
    }
    .teacher-page-header-content-right-img {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      width: 57px;
      height: 57px;
      img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }
    }
    .teacher-page-header-content-right-user {
      display: flex;
      margin-left: 20px;
      h3 {
        color: #940e34;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .teacher-page-header-content-right-user-category {
        color: #707070;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .teacher-page-header-content-right-dropdown {
        .dropdown {
          .btn-primary {
            padding: 0px 7px;
            display: flex;
            background: transparent;
            color: transparent;
            border: none;
            &:hover {
              background: transparent;
              color: transparent;
            }
            &:active {
              background: #ffffff !important;
                          }
          }
          .dropdown-menu {
            inset: 11px 20px auto auto !important;
            .dropdown-item {
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
.teacher-page-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 15px;
  height: 80px;
  box-shadow: 0px 0px 3px #000000;
  .teacher-page-header-content-left {
    display: flex;
    .teacher-page-header-content-left-icon {
      font-size: 25px;
      align-items: center;
      cursor: pointer;
    }
  }
  .teacher-page-header-content-right {
    display: flex;
    align-items: center;
    .teacher-page-header-content-right-bell {
      cursor: pointer;
    }
    .teacher-page-header-content-right-user {
      display: flex;
      margin-left: 20px;
      h3 {
        color: #940e34;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .teacher-page-header-content-right-user-category {
        color: #707070;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .teacher-page-header-content-right-dropdown {
        .dropdown {
          .btn-primary {
            display: flex;
            align-items: center;
            padding: 0px 7px;
            background: #ffffff;
            border: none;
            &:hover {
              background: transparent;
              color: transparent;
            }
            &:active {
              background: #ffffff !important;
            }
            .teacher-page-header-content-right-img {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 57px;
              height: 57px;
              img {
                cursor: pointer;
                width: 55px;
                height: 55px;
                border-radius: 50%;
              }
            }
          }
          .dropdown-menu {
            inset: 10px 0px auto auto !important;
            width: 302px;
            .dropdown-item {
              line-height: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            hr {
              margin: 5px 0px; 
            }
          }
        }
      }
    }
  }
}
