.rental-home-container {
  min-height: 108vh;
  margin: 0;
  padding: 40px 0 120px;
  background-size: contain;
  background-image: url("../../assets/images/rental-bg.jpg");
  background-repeat: no-repeat;
  background-color: #940e34;

  .options-container {
    margin-top: 30px;
    padding: 30px 30px;
    background-color: #ffffff;
    border-radius: 20px;
  }
}

.primary-content {
  margin: 0 15vw;
}

.rental-heading {
  width: 50%;
  margin-top: 40px;
  color: #940e34;
  font-weight: bold;
}

.interaction-container {
  margin-top: 40px;
  padding: 30px;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  .section-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #940e34;
    margin-bottom: 20px;
  }

  #section-one {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  #section-two {
    margin-top: 40px;

    .ant-radio-button-wrapper-checked {
      background-color: #940e34;
      border-color: #940e34;
    }
    .ant-radio-button-wrapper:hover {
      color: #940e34;
    }
    .ant-radio-button-wrapper-checked:hover {
      color: #ffffff;
    }
  }

  #section-three {
    margin-top: 40px;

    .pref-container {
      .pref-title {
        font-size: 0.9rem;
        font-weight: 600;
        color: #3d348b;
        text-align: center;
        border-bottom: 3px solid #940e34;
        padding-bottom: 10px;
        margin-bottom: 0;
      }

      .pref-checkbox-field {
        padding-right: 10px;
        background-color: rgba(0,0,0,0.04);

        .pref-checkbox {
          padding: 8px;

          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #940e34;
            border-color: #940e34;
          }
        }

        .pref-checkbox-label {
          margin-left: 10px;
        }
      }
    }
  }

  .actions-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}





.rounded-input {
  border-radius: 15px;
  border: none;
  padding: 0.5rem 1rem;
  font-size: small;
  width: 100%; /* Adjusted width to full width */
  margin-bottom: 10px;
  background-color: #f7f7f7;
}

.diff-card {
  background-color: #fff;
  margin: 0 2rem;
  padding: 1rem 0;
}

.posting-card {
  background-color: #e7e7e7;
  padding: 1rem;
  border-radius: 10px;
}

.img-grid {
  display: grid;
  grid-gap: 1rem;
  margin-top: -1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

/* Example of media query for larger screens */
@media only screen and (min-width: 768px) {
  .rounded-input {
    width: 70%; /* Adjusted width for larger screens */
  }

  .img-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.search-field {
  margin-top: 30px;
}