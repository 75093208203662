/* Table Container */
.table-container {
  width: 100%;
  overflow-x: auto;
  border-radius: 10px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

th {
  background-color: #efdbe1 !important;
}
.table-datas,
th {
  position: relative;
  border: none !important;
  padding: 14px !important;
}
.action-icon {
  color: #940e34;
}

/* Actual Table */
.responsive-table-body {
  border: none;
}
/* Example styles for alternating row colors */
.even-row {
  background-color: #fff;
  position: relative;
  /* Other styles... */
}

.odd-row {
  background-color: #fcf7f9;
  position: relative;
  /* Other styles... */
}

@media only screen and (min-width: 760px) {
  .responsive-table {
    width: 100%;
    min-width: 1600px;
    border-collapse: collapse;
    border: none;
    font-family: Arial, sans-serif;
  }
}

@media only screen and (max-width: 760px) {
  .responsive-table {
    width: 1600px;
    border-collapse: collapse;
    border: none;
    font-family: Arial, sans-serif;
  }
}


/* Table Header */
.responsive-table th {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  position: relative;
}

.responsive-table th span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

/* Table Body */
.responsive-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

/* Actions Column */
.actions button {
  margin-right: 5px;
}

/* Responsive Styles */
/* @media (max-width: 600px) {
  .responsive-table th,
  .responsive-table td {
    display: block;
    width: 100%;
  }

  .responsive-table th:last-child,
  .responsive-table td:last-child {
    text-align: left;
  }

  .responsive-table th span {
    right: 5px;
  }
} */
.prime-text {
  color: #940e34;
}
.tooltip-box > p:hover {
  background-color: #f7f7f785;
  color: #940e34;
}
.tooltip-box {
  position: absolute;
  background: #fff;
  right: 46%;
  border-radius: 10px;
  width: 125px;
  top: 92%;
  z-index: 3;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.tooltip-box::after {
  position: absolute;
  content: "\25BC";
  height: 1.25rem;
  color: #fff;
  top: -23px;
  left: 73%;
  rotate: 180deg;
  transform: scale(1.8, 1) translate(-33%, -50%);
}

.active {
  color: #0a0a0a;
}

.in-active {
  color: #a7a7a7;
}
