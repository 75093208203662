.post-page {
  .post-page-card {
    margin-top: 15px;
  }

  .post-page-card-child {
    .post-page-card-child-card {
      height: 100%;
      padding: 10px 10px 20px 10px;
      .ant-card-cover {
        display: flex;
        justify-content: center;
        .post-page-card-child-card-img {
          display: flex;
          align-items: center;
          width: 200px;
          height: 200px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .ant-card-body {
        padding: 0px;
        margin-top: 10px;
      }
    }
  }
}

.ant-modal-content {
  .ant-modal-header {
    .ant-modal-title {
      font-weight: bold;
      font-size: 18px;
      color: rgb(148, 14, 52);
    }
  }
  .ant-modal-body {
  }
  .ant-modal-footer {
    display: none;
  }
}
@media screen and (min-width: 760px) {
  .post-page {
    padding: 20px 20px 80px 320px;
  }
  .post-pagination {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding-left: 300px;
  }
  .ant-modal-content {
    padding: 10px;
    .ant-modal-body {
      form {
        padding: 29px 20px 0px 20px !important;
      }
    }
  }
}

@media screen and (max-width: 760px) {
    .post-page {
      padding: 15px 15px 140px 15px;
    }
    .post-pagination {
      position: fixed;
      width: 100%;
      bottom: 0;
    }
  .ant-modal-content {
    padding: 10px !important;
    .ant-modal-body {
      form {
        padding: 20px 20px 0px 10px !important;
      }
    }
  }
}
