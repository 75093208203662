.profile-page {
    .profile-page-details {        
        display: flex;
        .profile-page-details-left {
            background-color: #ffffff;
            color: #000;
            position: relative;
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center; /* Align content to the center */
            border-top-left-radius: 10px; /* Rounded top left corner */
            border-bottom-left-radius: 10px; /* Rounded bottom left corner */
            border: 2px solid #940e34;
            .profile-page-details-left-rating {
                width: 100%;
                display: flex;
                justify-content: center;
                position: absolute;
            }
        }
        .profile-page-details-right {
            background-color: #ffffff;
            height: 100%;
            flex: 2;
            flex-wrap: wrap; /* Wrap to next row when content doesn't fit */
            justify-content: start; /* Evenly space sections in the row */
        }
    }
}

@media screen and (min-width: 760px) {
    .profile-page {
        padding: 20px 20px 20px 320px;
        .profile-page-details {
            height: 500px;
            .profile-page-details-left {   
                padding: 30px;
                padding-bottom: 6rem;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                .profile-page-details-left-rating {
                    bottom: 40px;
                }
            }
            .profile-page-details-right {
                padding: 20px;
                border-top-right-radius: 10px; /* Rounded top right corner */
                border-bottom-right-radius: 10px; /* Rounded bottom right corner */
            }
        }
    }
}



@media screen and (min-width: 768px) and (max-width: 1023px) {
    .profile-page {
        .profile-page-details {
            .profile-page-details-left {  
                box-shadow: 0px 3px 8px rgba(0,0,41,0.16); 
                height: 400px;
                padding-bottom: 6rem;
                border-radius: 10px;
                .profile-page-details-left-rating {
                    bottom: 25px;
                }
            }
            .profile-page-details-right {
                box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
                padding: 15px;
                border-radius: 10px;
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .profile-page {
        padding: 15px;
        .profile-page-details {
            .profile-page-details-left {  
                box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
                height: 400px;
                padding: 15px;
                border-radius: 10px;
                padding-bottom: 4rem; 
                .profile-page-details-left-rating {                    
                    bottom: 25px; 
                }
            }
            .profile-page-details-right {
                box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
                padding: 15px;
                border-radius: 10px;
            }
        }
    }
}

.tuition-profile-form-container {
    padding-right: 20px;

    .profile-image {
        width: 120px;
        height: 120px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border: 2px solid #ffffff;
            border-radius: 60px;
            box-shadow: 0px 10px 10px #7070700d;
        }
    }

    .field-label {
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #d2144a;
        margin-bottom: 8px;
    }

    input, textarea, span {
        font-family: "Montserrat", sans-serif;
    }

    .submit-button {
        width: 200px;
        height: 48px;
        background: #940E34;

        &:hover {
            background: #d2144a !important;
        }
        &:disabled {
            background: rgba(0,0,0,0.1);
            &:hover {
                background: rgba(0,0,0,0.1) !important;
            }
        }
    }

    .error-alert {
        margin-bottom: 16px;
    }
}