html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

#root {
  height: 100%;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: "Montserrat", sans-serif;
  word-break: break-word;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  color: #012834;
  -webkit-text-size-adjust: 100%;
}

img {
  max-width: 100%;
}

/* @media (min-width: 1600px) {
  .container {
    max-width: 1435px;
  }
} */

.btn-primary {
  border-radius: 30px;
  background: #940e34;
  color: #ffffff;
  padding: 14px 46px;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  border: 1px solid #940e34;
}

.btn-primary:hover, .btn-primary:focus {
  background: transparent;
  color: #940e34;
  border-color: #940e34;
}

.btn-primary:active {
  background-color: #940e34;
  border-color: #940e34;
}

.btn-secondary {
  border-radius: 30px;
  background: transparent;
  color: #940e34;
  padding: 14px 36px;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  border: 1px solid #940e34;
}

.btn-secondary-disabled {
  border-radius: 30px;
  background: transparent;
  color: #940e34;
  padding: 14px 36px;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  border: 1px solid #940e34;
  pointer-events: none;
}

.btn-secondary:hover, .btn-secondary:focus {
  background: #940e34;
  color: #ffffff;
  border-color: #940e34;
}

.btn-secondary.active,
.btn-secondary.active:hover {
  background: #940e34;
  color: #ffffff;
  border-color: #940e34;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  /* border-radius: 30px; */
  background: #f7f7f7;
  border: none;
  padding: 20px;
  font-size: 14px;
  line-height: 130%;
  font-weight: 400;
  color: #012834;
  border: 1px solid #f7f7f7;
}

.form-control::placeholder {
  color: #707070;
}

.form-control:focus {
  box-shadow: none;
  border-color: #940e34;
}

.form-group .form-label {
  color: #707070;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 15px;
}

.form-group .icon-wrap {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  padding: 0;
  border: 0;
  background: border-box;
}

.form-group .icon-wrap img {
  width: 20px;
}

.form-group .icon-wrap img.close-eye {
  width: 18px;
}

.form-group .icon-wrap:active {
  background: transparent !important;
}

.form-group .input-group .form-control {
  /* border-radius: 30px !important; */
  padding-right: 46px;
}

/* .ant-select-focused {
  border: none !important;
  outline: none !important;
}

.ant-select-selector {
  background-image: url("../src/assets/images/arrow-down.svg");
  background-position: right 15px center;
  background-size: 14px 8px;
  background-repeat: no-repeat;
  border-radius: 20px !important;
}

.ant-select-dropdown {
  border-radius: 20px;
  background: #f7f7f7;
  padding: 10px 0;
  border: none;
} */

.modal-open .ant-select-dropdown {
  z-index: 9999;
}

.preloader-position {
  top: 50% !important;
}

/* .ant-select-item.ant-select-item-option-selected {
  background-color: #ffffff !important;
}

.ant-select-selection-placeholder {
  color: #000 !important;
}

.ant-select-item .anticon-check svg {
  fill: #940e34;
} */

.form-select {
  /* border-radius: 30px; */
  background-color: #f7f7f7;
  background-image: url("../src/assets/images/arrow-down.svg");
  background-position: right 15px center;
  background-size: 14px 8px;
  padding: 20px 10px;
  font-size: 14px;
  line-height: 130%;
  font-weight: 400;
  color: #012834;
  border: 1px solid #f7f7f7;
  border-radius: 20px;
}

.form-select:focus {
  box-shadow: none;
  border-color: #940e34;
}

/*  */
.text-gray {
  color: #959595;
}

.form-select option {
  background-color: #f7f7f7;
}

a {
  text-decoration: none;
}

/* .anticon svg {
  color: red !important;
} */
.pagination-bar {
  background-color: #fff !important;
  padding: 1rem 1.4rem;
  border-block: 0.001px solid lightgray;
}

.ant-checkbox-wrapper {
  padding: 10px !important;
}

.pac-container {
  border-radius: 20px;
  background: #f7f7f7;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
}

.pac-logo:after {
  display: none;
}

.pac-container .pac-item {
  font-weight: 700;
  font-size: 14px;
  color: #000;
}

.pac-container .pac-item:first-child {
  border: none;
}

.pac-container .pac-item .pac-icon {
  display: none;
}

.pac-container .pac-item .pac-matched {
  font-weight: 400;
}

.form-check .form-check-label a {
  color: #940e34;
  font-weight: 600;
  text-decoration: none;
}

.error {
  display: block;
  margin-top: 6px;
  padding-left: 6px;
  color: red;
  font-size: 14px;
}

.form-group .search-group {
  box-shadow: 0px 30px 30px #7070700d;
  border: 1px solid #7070701a;
  border-radius: 10px;
  background: #ffffff;
  padding: 5px 15px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  margin-top: 6px;
}

.form-group .search-group li {
  color: #012834;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 10px 0;
  list-style-type: none;
}

.form-group .search-group li:last-child {
  border: none;
}

@media (max-width: 1400px) {
  .form-control {
    padding: 14px 20px;
  }

  .form-select {
    padding: 14px 20px;
  }
}

.pointer {
  cursor: pointer !important;
}

.leaflet-container{
  height:405px;
  width:100%
}

.ant-spin-dot-item {
  background-color: #940e34 !important;
}

.video-modal {
  max-width: 672px;
}