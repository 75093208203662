.error-border {
  border: 1px solid red;
}

.pagination-bottom {
  position: fixed;
  height: 100px;
  bottom: 0;
  top: 90%;
  width: 100%;
}
.student-info {
  .student-info-fluid {
    .student-info-fluid-container {
      .student-info-fluid-container-details {
        .student-info-fluid-container-details-filter {
        }
      }
      .student-info-fluid-container-pagination {
        padding: 35px 0px;
        background: #ffffff;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        .ant-select-selector {
          background-color: #fbf6f7;
        }
      }
    }
  }
}

@media only screen and (max-width: 760px) {
  .student-info {
    padding: 15px;
  }
}

@media only screen and (min-width: 760px) {
  .student-info {
    padding: 20px 20px 20px 320px;
  }
}
