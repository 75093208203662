.rental-listing-page {
  .rental-listing-container {
    background-color: #d9d9d9;
    padding: 80px 15vw 120px;

    .controls-container {
      .controls-header {
        display: flex;
        align-items: center;

        .control-items {
          flex-grow: 1;
          background-color: #f3f3f3;
          padding: 16px 0;
          display: flex;
          justify-content: space-evenly;
          border-radius: 26px;
        }
        .rental-search {
          margin-left: 16px;
          .ant-btn {
            width: 52px;
            height: 52px;
          }
        }
      }

      .controls-body {
        background-color: #f3f3f3;
        border-radius: 24px;
        margin-top: 16px;
        padding: 16px;

        .control-title {
          margin-top: 30px;
          font-weight: 600;
        }
      }
    }

    .listing-container {
      height: 700px;
      background-color: #f3f3f3;
      border-radius: 24px;
    }
  }
}