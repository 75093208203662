.pie-chart-card {
  border: 1.5px solid #fff; /* #940e34 is the desired border color */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.pie-chart-card:hover strong {
  color: #940e34; /* #940e34 is the desired border color */
}

.pie-chart-card:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 2px solid #ca889a; /* #940e34 is the desired border color */
}
