.grid-container {
  padding: 20px 10px 0px 10px !important;
}
.grid-wrapper {
  --auto-grid-min-size: 17rem;
  display: grid !important;
  grid-gap: 2rem;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.grid-wrapper-post {
  --auto-grid-min-size: 18rem;
  display: grid !important;
  grid-gap: 2rem;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.grid-wrapper-review {
  --auto-grid-min-size: 23rem;
  display: grid !important;
  grid-gap: 2rem;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.grid-wrapper-profile {
  --auto-grid-min-size: 10rem;
  display: grid !important;
  grid-gap: 2rem;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.fmgtfi {
  justify-content: center;
}
.eplQlC {
  margin: auto 3px !important;
}
