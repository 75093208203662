.react-calendar {
  border: none;
  @media (max-width: 768px) {
    padding: 10px; /* Adjust the padding for smaller screens */
  }
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__next-button {
  font-weight: 900 !important;
  order: 2 !important;
}
.react-calendar__tile--active {
  background-color: #940e34 !important;
}
canvas {
  height: 300px !important;
  width: 630px !important;
}
.react-calendar__navigation__prev-button {
  font-weight: 900 !important;
  order: 1 !important;
}
.react-calendar__navigation {
  justify-content: space-between;
}
abbr[title] {
  text-decoration: none !important;
}
.react-calendar__month-view__days,
.react-calendar__tile {
  border: 1px solid #d9d8e2 !important;
}
.react-calendar__tile {
  padding: 12px 6.6667px !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  background-color: #f2f3f7;
  color: #c1c1c2 !important;
}
.react-calendar__navigation__label {
  text-align: left;
  font-weight: 900 !important;
  margin: 0 5px !important;
  @media (max-width: 768px) {
    text-align: center; /* Center the label for smaller screens */
    margin: 0 !important; /* Remove margin for smaller screens */
  }
}
.custom-chart-labels .chartjs-legend li span {
  display: inline-block;
  width: 12px; /* Set your desired width */
  height: 12px; /* Set your desired height */
  border-radius: 50%;
  margin-right: 8px; /* Adjust the spacing */
}

.dash-container {
  display: flex;
  justify-container: space-between;
}
@media only screen and (max-width: 900px) {
  .dash-container {
    display: block;
  }
  .react-calendar {
    width: 100% !important;
  }
}
