.base-modal {
  .modal-dialog {
    max-width: 90%;
    width: 800px;
  
    @media (max-width: 1199px) {
      width: 80%;
    }
  
    @media (max-width: 900px) {
      width: 90%;
    }
  
    @media (max-width: 575px) {
      max-width: initial;
      width: auto;
      padding: 10px;
    }
  }

  &.small {
    .modal-dialog {
      @media (min-width: 1200px) {
        width: 600px;
      }
    }
  }
  
  .modal-content {
    background-color: #FFFFFF;
    border-radius: 30px;
    border: none;
    box-shadow: 0px 3px 8px rgba(0,0,41,0.16);
  }
  
  .modal-header {
    background: #f7f7f7;
    padding: 32px 40px;
    border-bottom: none;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  
    .modal-title {
      font-size: 30px;
      line-height: 120%;
      font-weight: 800;
    }
  }
  
  .modal-body {
    max-height: 60vh;
    margin: 40px;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
  }
}
