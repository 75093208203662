.tm-custom-tabs {
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 18px;
    color: #d2144a;

    &:focus {
      color: #fa7e61;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #940e34;
    font-weight: bold;
  }

  .ant-tabs-ink-bar {
    background: #940e34;
  }

  .ant-tabs-nav::before {
    border-bottom: 2px solid rgba(0,0,0,0.1);
  }
}