.search-box {
  width: 100%;
  height: 35px;
  position: relative;
  display: flex;
}
.search-input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  border-right: none;
  outline: none;
  font-size: 15px;
  background: #f7f7f7;
}
.search-button {
  text-align: center;
  height: 35px;
  width: 40px;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 0 5px 5px 0;
  border-left: none;
  color: #940e34;
  background: #f7f7f7;
  font-size: 20px;
}
